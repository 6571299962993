import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

export const HomepageHeroImage = () => (
  <StaticImage
    src="../../images/two-people-browsing-own-up.png"
    alt="Two people browsing Own Up"
    placeholder="blurred"
    quality={60}
    width={628}
    breakpoints={[312, 400, 511, 767, 1023, 1279]}
  />
);

export const ConnectDesktop = () => (
  <StaticImage
    src="../../images/connect-desktop-image.png"
    alt="full-size preview of Own Up options on mobile device"
    placeholder="blurred"
    quality={60}
    objectFit="scale-down"
    style={{ height: '100%' }}
  />
);

export const PersonalizeDesktop = () => (
  <StaticImage
    src="../../images/personalize-desktop-image.png"
    alt="full-size preview of Own Up questionnaire on mobile device"
    placeholder="blurred"
    quality={60}
    objectFit="scale-down"
    style={{ height: '100%' }}
  />
);

export const ShopSaveDesktop = () => (
  <StaticImage
    src="../../images/shop_save-desktop-image.png"
    alt="full-size preview of Own Up options on mobile device"
    placeholder="blurred"
    quality={60}
    objectFit="scale-down"
    style={{ height: '100%' }}
  />
);

export const ConnectMobile = () => (
  <StaticImage
    src="../../images/connect-mobile-image.png"
    alt="full-size preview of Own Up options on mobile device"
    placeholder="blurred"
    quality={60}
  />
);

export const PersonalizeMobile = () => (
  <StaticImage
    src="../../images/personalize-mobile-image.png"
    alt="full-size preview of Own Up questionnaire on mobile device"
    placeholder="blurred"
    quality={60}
  />
);

export const ShopSaveMobile = () => (
  <StaticImage
    src="../../images/shop_save-mobile-image.png"
    alt="full-size preview of Own Up options on mobile device"
    placeholder="blurred"
    quality={60}
  />
);

export const WhyWorkWithUsImage = () => (
  <StaticImage
    src="../../images/woman-smiling-on-the-phone.png"
    alt="Woman smiling on the phone talking about her mortgage"
    placeholder="blurred"
    quality={60}
  />
);

export const TwoPeopleReadyToBuyAHome = () => (
  <StaticImage
    src="../../images/two-people-ready-to-buy-a-home.png"
    alt="Two people sit at a laptop discussing their housing options"
    placeholder="blurred"
    quality={60}
  />
);

export const InsightfulToolsArticle = () => (
  <StaticImage
    src="../../images/insightful-tools-article.png"
    alt=""
    placeholder="blurred"
    quality={60}
  />
);

export const BlogArticleOne = () => (
  <StaticImage
    src="../../images/blog-article-1-image.png"
    alt=""
    placeholder="blurred"
    quality={60}
  />
);

export const BlogArticleTwo = () => (
  <StaticImage
    src="../../images/blog-article-2-image.png"
    alt=""
    placeholder="blurred"
    quality={60}
  />
);
