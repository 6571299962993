import { useTrackingInstance } from '@rategravity/1pt-lib';
import {
  ownUpDarkTheme,
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridOffset,
  OwnUpGridWrapper,
  OwnUpThemeProvider
} from '@rategravity/own-up-component-library';
import { Link } from 'gatsby';
import React, { PropsWithChildren } from 'react';
import { HomepageHeroImage } from '../../static-images/home';
import {
  Button,
  ButtonWrapper,
  Content,
  Copy,
  Headline,
  ImageWrapper,
  Section,
  SubHeadline
} from './elements';

export const HeroMain = ({ children }: PropsWithChildren<{}>) => (
  <OwnUpThemeProvider theme={ownUpDarkTheme}>
    <Section aria-label="hero">
      <OwnUpGridWrapper>{children}</OwnUpGridWrapper>
    </Section>
  </OwnUpThemeProvider>
);

export const PureHero = ({ Image }: { Image: () => JSX.Element }) => {
  const trackingInstance = useTrackingInstance();
  const buyingOnClick = () => {
    trackingInstance.track('Click discover CTA', { 'page-location': `home-hero-buying` });
  };
  const refinanceOnClick = () => {
    trackingInstance.track('Click discover CTA', { 'page-location': `home-hero-refinancing` });
  };

  return (
    <Content>
      <OwnUpGridContainer variant="legacy">
        <OwnUpGridItem xs={12} md={5} xl={6}>
          <ImageWrapper>
            <Image />
          </ImageWrapper>
        </OwnUpGridItem>
        <OwnUpGridOffset xs={0} md={1} />
        <OwnUpGridItem xs={12} md={6} xl={5}>
          <Copy>
            <Headline variant="h1">Borrow with confidence</Headline>
            <SubHeadline variant="h2">Know more, save more, spend smarter.</SubHeadline>
            <ButtonWrapper>
              <Button component={Link} to="/onboarding/buying" onClick={buyingOnClick}>
                Buying
              </Button>
              <Button
                href={`${process.env.GATSBY_ONBOARDING}/refinance`}
                onClick={refinanceOnClick}
              >
                Refinancing
              </Button>
            </ButtonWrapper>
          </Copy>
        </OwnUpGridItem>
      </OwnUpGridContainer>
    </Content>
  );
};

export const Hero = () => {
  return <PureHero Image={HomepageHeroImage} />;
};
