import React from 'react';
import {
  BlogArticleOne,
  BlogArticleTwo,
  InsightfulToolsArticle
} from '../../../../components/static-images/home';

export const postSnippetsData = [
  {
    tag: 'RESOURCES',
    title: 'Insightful tools',
    summary:
      "Uncover the data you need to know you're getting a great deal. Monitor rate trends and more with our tools.",
    linkText: 'Check out our resources',
    href: '/rate-comparison',
    img: <InsightfulToolsArticle />
  },
  {
    tag: 'BLOG',
    title: 'First time homebuyer checklist',
    summary:
      "Homeownership is more permanent and more expensive than renting. So how do you know if you're ready?",
    linkText: 'Read more on our blog',
    href: `${process.env.GATSBY_BLOG}/first-time-homebuyer-checklist`,
    img: <BlogArticleOne />
  },
  {
    tag: 'BLOG',
    title: 'How do I shop around for a mortgage lender?',
    summary:
      'Finding the right mortgage lender can be an intimidating process. Own Up is here to help guide you through the process every step of the way.',
    linkText: 'Read more on our blog',
    href: `${process.env.GATSBY_BLOG}/how-do-i-shop-around-for-a-mortgage-lender`,
    img: <BlogArticleTwo />
  }
];
