import * as Sentry from '@sentry/gatsby';
import fetch from 'node-fetch';
import * as t from 'type-shift';

type BottomLineSuccess = {
  status: {
    code: 200;
    message: 'OK';
  };
  response: {
    bottomline: {
      /* eslint-disable @typescript-eslint/naming-convention */
      average_score: number;
      /* eslint-disable @typescript-eslint/naming-convention */
      total_reviews: number;
    };
  };
};

type BottomLineFailure = {
  status: {
    code: number;
    message: string;
  };
};

type BottomLineResponse = BottomLineSuccess | BottomLineFailure;

const bottomLine200Converter = t.shape<BottomLineSuccess>({
  status: t.shape({
    code: t.literal(200),
    message: t.literal('OK')
  }),
  response: t.shape({
    bottomline: t.strict({
      /* eslint-disable @typescript-eslint/naming-convention */
      average_score: t.number,
      /* eslint-disable @typescript-eslint/naming-convention */
      total_reviews: t.number
    })
  })
});

const bottomLine400Converter = t.shape<BottomLineFailure>({
  status: t.shape({
    code: t.number,
    message: t.string
  })
});

type UnknownResponse = Record<string, string> & {
  status: { code: number };
};

export const bottomLineResponseConverter = (response: UnknownResponse): BottomLineResponse => {
  if (response.status.code === 200) {
    return bottomLine200Converter(response) as BottomLineSuccess;
  } else {
    return bottomLine400Converter(response) as BottomLineFailure;
  }
};

// c.f. https://apidocs.yotpo.com/reference/retrieve-bottom-line-for-all-site-reviews
export const fetchYotpoReviewsBottomLine = async (fetchClient = fetch) => {
  const url = `https://api.yotpo.com/products/${process.env.GATSBY_YOTPO_APP_KEY}/yotpo_site_reviews/bottomline`;
  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  const response = await fetchClient(url, options);
  const json = await response.json();

  try {
    const bottomLine: BottomLineResponse | undefined =
      json.status && 'code' in json.status ? bottomLineResponseConverter(json) : undefined;

    if (!bottomLine) {
      Sentry.captureException(
        `Malformed response from Yotpo Bottomline API: ${JSON.stringify(json)}`
      );
      return undefined;
    }

    if (bottomLine.status.code !== 200) {
      Sentry.captureException(`Failed call to Yotpo Bottomline API: ${bottomLine.status.message}`);
      return undefined;
    }

    return (bottomLine as BottomLineSuccess).response.bottomline;
  } catch (err) {
    Sentry.captureException(JSON.stringify(err));
    return undefined;
  }
};
