import { useTrackingInstance } from '@rategravity/1pt-lib';
import {
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridOffset,
  OwnUpGridWrapper,
  SAGE_100,
  WHITE_100
} from '@rategravity/own-up-component-library';
import { ArrowRightIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/arrow-right';
import { Link } from 'gatsby';
import React from 'react';
import { TwoPeopleReadyToBuyAHome } from '../../static-images/home';
import { RoundedSection } from '../rounded-section';
import {
  AnkleCta,
  CTAContent,
  CtaHeadline,
  DisplayOnDesktop,
  DisplayOnMobile,
  ImgWrapper,
  ImgWrapperContainer,
  Section,
  Spacer
} from './elements';
export interface Ankle {
  image: JSX.Element;
}

export const PureAnkleInner = ({ image }: Ankle) => {
  const trackingInstance = useTrackingInstance();
  const onClick = () => {
    trackingInstance.track('Click discover CTA', { 'page-location': 'ankle' });
  };

  return (
    <OwnUpGridContainer variant="legacy">
      <OwnUpGridOffset xs lg={1} />
      <ImgWrapperContainer xs={8} sm={6} lg={4}>
        <ImgWrapper>{image}</ImgWrapper>
      </ImgWrapperContainer>
      <OwnUpGridOffset xs lg={1} />
      <OwnUpGridItem xs={12} lg={5}>
        <CTAContent>
          <CtaHeadline variant="h2">Make the first move.</CtaHeadline>
          <AnkleCta
            iconPosition="right"
            icon={<ArrowRightIcon />}
            component={Link}
            to={'/onboarding/get-started'}
            onClick={onClick}
          >
            Get started
          </AnkleCta>
        </CTAContent>
      </OwnUpGridItem>
      <OwnUpGridOffset xs={0} lg={1} />
    </OwnUpGridContainer>
  );
};

const PureAnkleDesktop = ({ image }: Ankle) => (
  <DisplayOnDesktop>
    <RoundedSection topColor={SAGE_100} bottomColor={WHITE_100} position="left">
      <OwnUpGridWrapper>
        <Section>
          <PureAnkleInner image={image} />
        </Section>
      </OwnUpGridWrapper>
    </RoundedSection>
  </DisplayOnDesktop>
);

const PureAnkleMobile = ({ image }: Ankle) => (
  <DisplayOnMobile>
    <Section>
      <OwnUpGridWrapper>
        <PureAnkleInner image={image} />
      </OwnUpGridWrapper>
    </Section>
  </DisplayOnMobile>
);

export const PureAnkle = ({ image }: Ankle) => (
  <React.Fragment>
    <Spacer />
    <PureAnkleMobile image={image} />
    <PureAnkleDesktop image={image} />
  </React.Fragment>
);

export const Ankle = () => <PureAnkle image={<TwoPeopleReadyToBuyAHome />} />;
