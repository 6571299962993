import {
  OwnUpBodyMedium,
  OwnUpGridItem,
  PropsWithTheme,
  SLATE_100
} from '@rategravity/own-up-component-library';
import styled from 'styled-components';

export const LogoGridWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(5)}px;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xs')} {
    flex-direction: column;
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    flex-direction: row;
  }
`;

export const LogoGridItem = styled(OwnUpGridItem)`
  > :first-child {
    margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(5)}px;
  }
`;

export const Section = styled.section`
  background-color: ${({ theme }: PropsWithTheme<{}>) => theme.palette.background.default};
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xs')} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(10, 0, 5)};
  }
`;

export const FeaturedInText = styled(OwnUpBodyMedium)`
  color: ${SLATE_100};
  margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(-0.5)}px;
`;
