import { useTrackingInstance } from '@rategravity/1pt-lib';
import {
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpLineButton,
  OwnUpSmallHeadlineMedium
} from '@rategravity/own-up-component-library';
import * as Sentry from '@sentry/gatsby';
import { Link } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import { reviews } from '../../../data/content/home/reviews';
import { YotpoBadge } from '../../../images/yotpo-badge';
import { fetchYotpoReviewsBottomLine } from '../../../modules/fetch-yotpo-reviews-bottom-line';
import { ContentWrapper } from '../elements';
import { BadgeContainer, ButtonWrapper, Review, ReviewsWrapper, Spacer } from './elements';

export const floorToNearestHundred = (num: number) => Math.floor(num / 100) * 100;
export const roundToNearestTenth = (num: number) => Math.round(num * 10) / 10;

export const PureReviews = ({
  averageScore,
  title
}: {
  averageScore: number | undefined;
  title: string;
}) => {
  const trackingInstance = useTrackingInstance();
  const onClick = () => {
    trackingInstance.track('click-Reviews-home', {
      'page-location': 'More reviews'
    });
  };

  return (
    <section aria-label={title}>
      <ContentWrapper>
        <OwnUpSmallHeadlineMedium variant="h2">{title}</OwnUpSmallHeadlineMedium>
        <ReviewsWrapper>
          <OwnUpGridContainer variant="legacy">
            <OwnUpGridItem xs={12} md={7} lg={4}>
              <Review {...reviews[0]} />
            </OwnUpGridItem>
            <OwnUpGridItem xs={12} md={0} lg={4}>
              <Review {...reviews[1]} />
            </OwnUpGridItem>
            <OwnUpGridItem xs={12} md={5} lg={4}>
              <BadgeContainer>
                <YotpoBadge averageScore={averageScore} />
              </BadgeContainer>
            </OwnUpGridItem>
          </OwnUpGridContainer>
        </ReviewsWrapper>
        <ButtonWrapper>
          <OwnUpLineButton component={Link} to="/reviews" onClick={onClick}>
            More reviews
          </OwnUpLineButton>
        </ButtonWrapper>
        <Spacer />
      </ContentWrapper>
    </section>
  );
};

export const Reviews = () => {
  const [averageScore, setAverageScore] = useState<number | undefined>(undefined);
  const [totalReviews, setTotalReviews] = useState<number | undefined>(undefined);

  const isBottomlineFetched = useRef(false);

  useEffect(() => {
    const fetchBottomline = async () => {
      try {
        const bottomline = await fetchYotpoReviewsBottomLine();

        if (bottomline) {
          setAverageScore(roundToNearestTenth(bottomline.average_score));
          setTotalReviews(floorToNearestHundred(bottomline.total_reviews));

          if (bottomline.average_score < 4) {
            Sentry.captureMessage(
              `Average Yotpo review score is less than 4: ${bottomline.average_score}`
            );
          }
        }
      } finally {
        isBottomlineFetched.current = true;
      }
    };

    if (!isBottomlineFetched.current) {
      fetchBottomline().catch((err) => Sentry.captureException(err));
    }
  }, [setAverageScore, setTotalReviews]);

  const hasReviews = averageScore !== undefined && totalReviews !== undefined;
  const title = hasReviews
    ? `Join over ${totalReviews} happy borrowers`
    : 'Join other happy borrowers';

  return <PureReviews averageScore={averageScore} title={title} />;
};
