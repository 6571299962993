import React from 'react';
import { AsFeaturedIn } from '../components/home/as-featured-in';
import { DescriptiveImageLinks } from '../components/home/descriptive-image-links';
import { ExpandYourKnowledgeMain, MiddleMain } from '../components/home/dog-ear-section';
import { ExpandYourKnowledge } from '../components/home/expand-your-knowledge';
import { Ankle } from '../components/home/floating-image-with-text';
import { Hero, HeroMain } from '../components/home/hero';
import { InnovativeTechnology } from '../components/home/innovative-technology';
import { Reviews } from '../components/home/reviews';
import { WhyWorkWithUs } from '../components/home/why-work-with-us';
import { Layout } from '../components/layout';

export const Home = () => (
  <Layout>
    <HeroMain>
      <Hero />
    </HeroMain>
    <MiddleMain>
      <InnovativeTechnology />
      <WhyWorkWithUs />
      <Reviews />
    </MiddleMain>
    <ExpandYourKnowledgeMain>
      <ExpandYourKnowledge />
      <DescriptiveImageLinks />
    </ExpandYourKnowledgeMain>
    <Ankle />
    <AsFeaturedIn />
  </Layout>
);

export default Home;
