import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

const NPR = () => (
  <StaticImage
    src="../../images/npr.png"
    alt="National Public Radio (NPR)"
    placeholder="blurred"
    quality={100}
    height={32}
    imgStyle={{ height: 32, width: 'fit-content' }}
    objectFit="scale-down"
  />
);

const Forbes = () => (
  <StaticImage
    src="../../images/forbes.png"
    alt="Forbes"
    placeholder="blurred"
    quality={100}
    height={32}
    imgStyle={{ height: 32, width: 'fit-content' }}
    objectFit="scale-down"
  />
);

const YahooFinance = () => (
  <StaticImage
    src="../../images/yahoo-finance.png"
    alt="Yahoo Finance"
    placeholder="blurred"
    quality={100}
    height={32}
    imgStyle={{ height: 32, width: 'fit-content' }}
    objectFit="scale-down"
  />
);

const USNews = () => (
  <StaticImage
    src="../../images/us-news.png"
    alt="U.S. News"
    placeholder="blurred"
    quality={100}
    height={32}
    imgStyle={{ height: 32, width: 'fit-content' }}
    objectFit="scale-down"
  />
);

const Bloomberg = () => (
  <StaticImage
    src="../../images/bloomberg.png"
    alt="Bloomberg News"
    placeholder="blurred"
    quality={100}
    height={32}
    imgStyle={{ height: 32, width: 'fit-content' }}
    objectFit="scale-down"
  />
);

const USAToday = () => (
  <StaticImage
    src="../../images/usa-today.png"
    alt="USA Today"
    placeholder="blurred"
    quality={100}
    height={32}
    imgStyle={{ height: 32, width: 'fit-content' }}
    objectFit="scale-down"
  />
);

export const images = [
  [<USNews key="us-news" />, <Forbes key="forbes" />, <USAToday key="usa-today" />],
  [<YahooFinance key="yahoo-finance" />, <Bloomberg key="bloomberg" />, <NPR key="npr" />]
];
