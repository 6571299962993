import {
  ALOE_100,
  OwnUpFillButtonPrimaryAccent,
  OwnUpLargeHeadlineMedium,
  OwnUpSmallSubheadlineMedium,
  PropsWithTheme
} from '@rategravity/own-up-component-library';
import styled from 'styled-components';
import { dogEarBottomOffset } from '../elements';

export const Section = styled.section`
  background-color: ${ALOE_100};
  color: ${({ theme }: PropsWithTheme<{}>) => theme.palette.text.primary};

  ${dogEarBottomOffset}
`;

export const Content = styled.div`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xs')} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(5, 0, 10)};
  }

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xl')} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(6, 0, 10)};
  }
`;

export const Copy = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xl')} {
    justify-content: end;
  }
`;

export const Headline = styled(OwnUpLargeHeadlineMedium)`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xs')} {
    padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(3)}px;
  }

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(0)}px;
  }
`;

export const SubHeadline = styled(OwnUpSmallSubheadlineMedium)`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xs')} {
    padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2)}px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(1.5)}px;

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xs')} {
    flex-direction: column;
    padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(4)}px;
  }

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('sm')} {
    flex-direction: row;
  }

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('lg')} {
    padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(5)}px;
  }
`;

export const Button = styled(OwnUpFillButtonPrimaryAccent)`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xs')} {
    width: 100%;
  }

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('sm')} {
    width: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(18)}px;
  }
`;

export const ImageWrapper = styled.div`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('sm')} {
    max-width: 480px;
  }

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xl')} {
    margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(-24)}px;
  }
`;
