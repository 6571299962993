import {
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridWrapper,
  OwnUpThemeProvider,
  ownUpWhiteTheme
} from '@rategravity/own-up-component-library';
import React from 'react';
import { images as pressImages } from '../../static-images/press';
import { FeaturedInText, LogoGridItem, LogoGridWrapper, Section } from './elements';

export const PureAsFeaturedIn = ({ images }: { images: JSX.Element[][] }) => (
  <OwnUpThemeProvider theme={ownUpWhiteTheme}>
    <Section aria-label="press logos">
      <OwnUpGridWrapper>
        <OwnUpGridContainer variant="legacy">
          <OwnUpGridItem xs={12}>
            <FeaturedInText variant="body1">Featured in</FeaturedInText>
          </OwnUpGridItem>
          <LogoGridItem>
            {images.map((imageArr, index) => (
              <LogoGridWrapper key={index}>
                {imageArr.map((image, i) => (
                  <OwnUpGridItem key={i}>{image}</OwnUpGridItem>
                ))}
              </LogoGridWrapper>
            ))}
          </LogoGridItem>
        </OwnUpGridContainer>
      </OwnUpGridWrapper>
    </Section>
  </OwnUpThemeProvider>
);

export const AsFeaturedIn = () => <PureAsFeaturedIn images={pressImages} />;
